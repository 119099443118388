<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Управление на данните на продукта</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
            <div class="form-group">
              <label>Име на продукта:</label>
              <input
                type="text"
                class="form-control"
                v-model="product.name"
                placeholder="пр. За нас"
              >
            </div>
            <div class="form-group">
              <label>Url:</label>
              <input type="text" class="form-control" v-model="product.url" placeholder>
            </div>
            <div class="form-group">
              <label>Описание:</label>
              <editor
                v-model="product.description"
                placeholder="da"
                :init="editor_init"  
                licenseKey="gpl"
              ></editor>
            </div>
            <div class="form-group">
              <label>Техническо описание:</label>
              <editor
                v-model="product.technical_description"
                placeholder="da"
                :init="editor_init"  
                licenseKey="gpl"
              ></editor>
            </div>
            <div class="form-group">
              <label>Текст за внимание (в начланта страница):</label>
              <input
                type="text"
                class="form-control"
                v-model="product.attentionText"
                placeholder=""
              >
            </div>
            <div class="form-group">
              <label>Цена купува:</label>
              <input
                type="text"
                class="form-control"
                v-model="product.buy_price"
                placeholder="пр. 10.3"
              >
            </div>
            <div class="form-group">
              <label>Цена продава:</label>
              <input
                type="text"
                class="form-control"
                v-model="product.sell_price"
                placeholder="пр. 20.50"
              >
            </div>

            <div class="form-group">
              <label>Чисто злато (в грамове):</label>
              <input
                type="text"
                class="form-control"
                v-model="product.price_calc_weight"
                placeholder="пр. 20.50"
              >
            </div>
            <div class="form-group">
              <label>Презентационо тегло:</label>
              <input
                type="text"
                class="form-control"
                v-model="product.public_calc_weight"
                placeholder="пр. 20.50"
              >
            </div>
            <div class="form-group">
              <label>Презентационо тегло (в свързани продукти):</label>
              <input
                type="text"
                class="form-control"
                v-model="product.presentation_weight"
                placeholder
              >
            </div>
            <div class="form-group">
              <label>Комисионна при продажба (в %):</label>
              <input
                type="text"
                class="form-control"
                v-model="product.sellPriceCommision"
                placeholder="пр. 20.50"
              >
            </div>
            <div class="form-group">
              <label>Комисионна при покупка (в %):</label>
              <input
                type="text"
                class="form-control"
                v-model="product.buyPriceComission"
                placeholder="пр. 20.50"
              >
            </div>

            <div class="form-group">
              <label>Тип:</label>
              <select v-model="product.type" required>
                <option value="AU">Злато</option>
                <option value="AG">Сребро</option>
                <option value="PT">Платина</option>
                <option value="PD">Паладий</option>
              </select>
            </div>

            <div class="form-group">
              <label>Авт. обновяване: </label>
              <select v-model="product.auto_update" required>
                <option value="Y">Да</option>
                <option value="N">Не</option>
              </select>
            </div>

            <div class="form-group">
              <label>Статус:</label>
              <select v-model="product.status" required>
                <option value="Public">Публикуван</option>
                <option value="Hidden">Скрит</option>
              </select>
            </div>

            <div class="form-group">
              <label>Мигаща нотификационна точка:</label>
              <select v-model="product.blink">
                <option value="Y">Да</option>
                <option value="N">Не</option>
              </select>
            </div>

            <div class="form-group">
              <label>Покажи стотинки в цената:</label>
              <select v-model="product.showDecimal" required>
                <option value="Y">Да</option>
                <option value="N">Не</option>
              </select>
            </div>

            <div class="form-group">
              <label>ДДС група:</label>
              <select v-model="product.vatGroupId" required>
                <option value="1">А (0%)</option>
                <option value="2">Б (20%)</option>
              </select>
            </div>

            <div class="form-group">
              <label><span>Категория 1</span></label> 
              <select v-model="product.categoryId1" class="form-control" required>
                <option disabled value="">-- Nothing selected --</option> 
                <option v-for="option in listOfOptions" :key="option.value" :value="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
            
            <div v-if="product.url != '--'">
              <div class="form-group">
                <label><span>Категория 2</span></label> 
                <select v-model="product.categoryId2" class="form-control">
                    <option value="">-- Nothing selected --</option> 
                    <option v-for="option in listOfOptions" :key="option.value" :value="option.id">
                      {{ option.name }}
                    </option>
                </select>
              </div>

              <div class="form-group">
                <label><span>Категория 3</span></label> 
                <select v-model="product.categoryId3" class="form-control">
                  <option value="">-- Nothing selected --</option> 
                  <option v-for="option in listOfOptions" :key="option.value" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
              <div class="float-right">
                <router-link :to="{name:'ProductsList'}">Отказ</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';
import Editor from '@tinymce/tinymce-vue';

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    submit: function () {
      let data = JSON.parse(JSON.stringify(this.product))
      if (this.id !== undefined) {
        data['id'] = this.id
      }
      ui.block($('#_block_me'))
      api.post('product/save', data).then(
        function (res) {
          this.$router.push('/products/lists')
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    },
    get_data: function () 
    {
      if (this.id !== undefined) {
        ui.block($('#_block_me'))
        api.get('product/get/' + this.id).then(
          function (res) {
            this.product = res.data.product;
            ui.unblock($('#_block_me'))
          }.bind(this)
        )
      }
      this.getListParentCategorySelect();
    },
    getListParentCategorySelect: function()
    {
      console.log("call getListParentCategorySelect");
      api.get('categories/list').then(function (res) 
      {
            // only for seprated line
            if (this.product.url == '--')
            {
              for (const [key, value] of Object.entries(res.data.payload)) 
              {
                if (value.level == 1) 
                {
                  this.listOfOptions.push({ id: value.id, name: value.name });
                }
              }
            }
            else // all product expect the sperated line
            {
              for (const [key, value] of Object.entries(res.data.payload)) 
              {
                // show only level 2 or level 1 but that doesn't have sub-categories.
                if (value.level == 2 || (value.level == 1 && value.hasSubCategories == 0)) 
                {
                  this.listOfOptions.push({ id: value.id, name: "(" + value.level + ") " +value.name });
                }
              }
            }

          }.bind(this)
      );
    }
  },
  props: ['id'],
  components: {
    editor: Editor,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  
  mounted: function () {
    this.get_data();
  },
  data () {
    return {
      listOfOptions: [],
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Продукти',
          link: { name: 'ProductsList' }
        }
      ],
      editor_init: {
        license_key: 'gpl',
        height: 300,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'link',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount'
        ],
        toolbar:
          'formatselect | bold italic | alignleft aligncenter alignright alignjustify | removeformat | link |  numlist bullist outdent indent | image'
      },
      product: {
        name: '',
        url: '',
        description: '',
        technical_description: '',
        attentionText: '',
        status: '',
        sell_price: '',
        buy_price: '',
        public_calc_weight: '',
        price_calc_weight: '',
        auto_update: '',
        sellPriceCommision: '',
        buyPriceComission: '',
        blink: 'N',
        showDecimal: '',
        vatGroupId: '',
        type: ''
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>
