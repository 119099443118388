<template>
    <div id="app">

      <div v-if="!logged">
        <Login />
      </div>

      <div v-else>
        <div class="navbar navbar-expand-md navbar-light navbar-static">
          <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
            <div class="navbar-brand navbar-brand-md">
              <router-link class="d-inline-block" to="/">
                <img src="./assets/images/logo_light.png" alt="">
              </router-link>
            </div>
            <div class="navbar-brand navbar-brand-xs">
              <router-link class="d-inline-block" to="/"> </router-link>
            </div>
          </div>
          <div class="d-flex flex-1 d-md-none">
            <div class="navbar-brand mr-auto">
              <a href="../full/index.html" class="d-inline-block">
                Blah
              </a>
            </div>
            <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
              <i class="icon-paragraph-justify3"></i>
            </button>
          </div>

          <div class="collapse navbar-collapse" id="navbar-mobile">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                  <i class="icon-paragraph-justify3"></i>
                </a>
              </li>  
            </ul>

            <ul class="navbar-nav ml-auto">
              
                <a href="#" @click.prevent="logout">Изход</a>
              
            </ul>
          </div>
        </div>

        <div class="page-content">
          <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">
            <div class="sidebar-mobile-toggler text-center">
              <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
              </a>
              Navigation
              <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
              </a>
            </div>
            <div class="sidebar-content">
              <div class="card card-sidebar-mobile">
                <Nav />
              </div>
            </div>
          </div>

          <div class="content-wrapper">
            <div class="my_min_h">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <style lang="less" scoped>
    ._nav_dropdown {
      display: block;
    }
    ._nav_dropdown:hover {
      a.navbar-nav-link { color: #333 !important; }
      .dropdown-menu {
        display: block;
      }
    }
  </style>

  <script>
  import my_app from "./assets/js/app.js";
  import LeftNav from "@/components/template/LeftNav";

  import LoginTemplate from "@/components/template/NotLogged";
  import Storage from "@/storage";
  import { EventBus } from "./helpers/event-bus";
  import api from "@/api_client";

  export default {
    name: "App",
    methods: {
      logout: function() {
        EventBus.emit("logout");
        
      },
      change_app: function(event) {
        if(event.id == "new") {

        } else {
          this.selected_app = event.title;
          Storage.set("selected_app_name",event.title);
        }
      },
      update_ui: function() {
        var h = $(window).height();

        if ($(".page-header")) {
          var h2 = $(".page-header").height();
          if (h2 != undefined) {
            h = parseInt(h - parseInt(h2 - 30));
          } else {
            h = parseInt(h - 95);
          }
        }
        $(".my_min_h").css({ "min-height": h });
      }
    },
    updated: function() {
      this.$nextTick(
        function() {
          my_app.initCore();
          this.update_ui();
          setTimeout(function(){ this.update_ui(); }.bind(this),700);
        }.bind(this)
      );
    },
    mounted: function() {
      my_app.initCore();
      this.update_ui();

      let self = this;
   
      EventBus.on("logout", function() {
        Storage.clear();
        self.user = {};
        self.logged = false;
        self.$router.replace({ name: "Login" });
      });
      EventBus.on("login", function() {
        self.user = Storage.get("user");
        self.logged = true;
      });

      /// check if tokens are valid
      api.post("/ping").then(res => {
        if (typeof res.data === "object") {
          Storage.set("user", res.data.data);
        } else {
          EventBus.emit("logout");
        }
      }).catch((error) => { EventBus.emit("logout"); });
    },
    components: { Nav: LeftNav, Login: LoginTemplate },
    data() {
      let token = Storage.get("token");
      let logged = token != undefined ? true : false;
      let user = Storage.get("user");

      return {
        logged: logged,
        user: user,
        selected_app: Storage.get("selected_app_name") ? Storage.get("selected_app_name") : "Select app",
        user_apps: []
      };
    }
  };
  </script>

  <style src="./assets/css/bootstrap.css"></style>
  <style src="./assets/css/bootstrap_limitless.css"></style>
  <style src="./assets/css/layout.css"></style>
  <style src="./assets/css/components.css"></style>
  <style src="./assets/css/colors.css"></style>
  <style src="./assets/icons/icomoon/styles.css"></style>