<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">{{title}}</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
            <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
            <div class="form-group">
              <label>Нова Парола:</label>
              <input type="password" class="form-control" name="password" v-model="model.password" required>
            </div>
            <br>
            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
              <div class="float-right">
                <router-link :to="{name:'UsersList'}">Отказ</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.tox-statusbar__branding {
  display: none !important;
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Editor from "@tinymce/tinymce-vue";

import api from "@/api_client";
import ui from "@/helpers/ui";

import PageHeader from "@/components/template/PageHeader";

library.add(faPencilAlt);
import _ from 'lodash';

export default {
  name: "List",
  methods: {
    submit: function() {
      let data = _.clone(this.model);
      ui.block(
        $("#_block_me"),
        '<i class="icon-spinner4 spinner mr-2"></i>&nbsp; Запазване на промените'
      );
      api.post("users/save/" + this.id, data).then(
        function() {
          ui.unblock($("#_block_me"));
          this.$router.push("/users/list");
        }.bind(this)
      );
    },
    get_data: function() {
      this.model.id = this.id;
    }
  },
  components: {
    editor: Editor,
    "font-awesome-icon": FontAwesomeIcon,
    PageHeader: PageHeader
  },
  props: ["id"],
  mounted: function() {
    this.get_data();
  },
  data() {
    return {
      title: "Редактиране на потребител",
      breadbrumbs: [
        {
          name: "Начало",
          icon: "icon-home2 mr-2",
          link: { name: "HelloWorld" }
        }
      ],
      page: {
        title: "",
        content: ""
      },
      model: { id: 0, password: "" },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  }
};
</script>