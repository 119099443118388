<template>
  <div class="content d-flex justify-content-center align-items-center">
    <form class="login-form" action="" v-on:submit.prevent="submit">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <img src="@/assets/logo.png" width="100" alt="">
            <h5 class="mb-0">Login to your account</h5>
            <span class="d-block text-muted">Enter your credentials below</span>
          </div>
          <div v-if="login_error_message" class="alert bg-danger text-white">
            {{login_error_message}}
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="text" v-model.trim="username" class="form-control" :placeholder="Username">
            <div class="form-control-feedback">
              <i class="icon-user text-muted"></i>
            </div>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="password" v-model.trim="password" class="form-control" :placeholder="Password">
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block legitRipple">Sign in<i class="icon-circle-right2 ml-2"></i></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="less" scoped>
.card {
  width: 350px;
}
</style>

<script>
import Store from "@/storage";
import api from "@/api_client";
import {EventBus} from '@/helpers/event-bus';

export default {
  name: "Login",
  methods: {
    submit: function() {
      this.login_error_message = "";
      if (this.username == "" || this.password == "") {
        this.login_error_message = "Please enter your details";
      } else {
        let self = this;
        // let events = events;
        api
          .post("/user/login", {
            email: this.username,
            password: this.password
          })
          .then(res => {
            let data = res.data
            
            if (data.status === false) 
            {
              this.login_error_message = "Username/Password is not valid";
            } 
            else 
            {
              Store.set("token", data.token);
              Store.set("user", JSON.stringify(data.user_data));
              EventBus.emit("login");
              self.$router.replace({ name: "HelloWorld" });
            }
          });
      }
    }
  },
  mounted: function() {
    this.$emit("logout");
  },
  data() {
    return {
      username: "",
      password: "",
      login_error_message: ""
    };
  },
};
</script>