// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card[data-v-657094ba] {
  width: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.vue","webpack://./Login.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF","sourcesContent":["\n.card {\n  width: 350px;\n}\n",".card {\n  width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
