<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Управление на файлове</h5>
          <div>
            <router-link :to="{name:'FilemanagerImagesUpload'}" class="btn legitRipple"> <font-awesome-icon icon="plus"></font-awesome-icon> Добави</router-link>
          </div>
        </div>
        <div class="card-body" id="_block_me">
          <div class="table-responsive">
            <table class="own_table table text-nowrap">
              <thead>
                <tr>
                  <th class="first_td">Качено на</th>
                  <th>Линк</th>
                  <th>Демо</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pages">
                  <td class="first_td">{{item.name}}</td>
                  <td class="first_td">/{{item.url}}</td>
                  <td><img style="height: 100px" v-bind:src="'/'+item.url"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import api from '@/api_client'
import ui from '@/helpers/ui'

import PageHeader from '@/components/template/PageHeader'

library.add(faPencilAlt, faPlus)

export default {
  name: 'List',
  methods: {
    get_data: function () {
      ui.block($('#_block_me'))
      api.get('filemanager/list').then(
        function (res) {
          this.pages = res.data.pages
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Файлове',
          link: { name: 'FilemanagerImagesUpload' }
        }
      ],
      pages: []
    }
  }
}
</script>
