<template>
  <div>
      <PageHeader
          title="Dashboard"
          :breadbrumbs="[]"
          title_data=""
      />
      <div class="content">
          Моля изберете секция от менюто от ляво.
      </div>
  </div>
</template>

<script>
import PageHeader from "@/components/template/PageHeader";

export default {
components: { PageHeader: PageHeader },
};
</script>

