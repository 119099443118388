var axios = require('axios');

import Store from '@/storage'

var axiosInstance = axios.create({
  baseURL: '/backend/api',
  // old backend keep for a while
  // baseURL: '/api', 
  transformRequest: [function (data, headers) {
    
    // auth token in headers
    const token = Store.get("token");

    if(token != undefined) {
      headers['Auth-token'] = token;
    }

    if(data == undefined) {
      data = {};
    }
    let formData = new FormData();
      Object.keys(data).forEach(attr => {
        formData.append(attr, data[attr]);
      });
      if(token != undefined) {
        formData.append("token", token );
      }
      return formData;
  }],
});

export default axiosInstance;