<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">FAQ</h5>
          <router-link :to="{name:'FAQAdd'}">Добави</router-link>
        </div>
        <div class="card-body" id="_block_me">
          <div class="table-responsive">
            <table class="own_table table text-nowrap">
              <thead>
                <tr>
                  <th class="first_td">Заглавие</th>
                  <th class="text-right" style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in faq">
                  <td class="first_td">{{item.title}}</td>
                  <td>
                    <router-link :to="{name:'FAQEdit', params: {'id':item.id} }">
                      <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </router-link>&nbsp;
                    <a href="#" v-on:click.prevent="delMe(item)">
                      <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt, faPlus, faTrashAlt)

export default {
  name: 'List',
  methods: {
    delMe: function (el) {
      if (confirm('Сигурни ли сте ?')) {
        api.post('faq/delete', { id: el.id }).then(
          function () {
            this.get_data()
          }.bind(this)
        )
      }
    },
    get_data: function () {
      ui.block($('#_block_me'))
      api.get('faq/list').then(
        function (res) {
          this.faq = res.data.faq
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        }
      ],
      faq: []
    }
  }
}
</script>
