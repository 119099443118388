<template>
  <div>
    <ul class="nav nav-sidebar" data-nav-type="accordion">
      <li class="nav-item-header">
        <div class="text-uppercase font-size-xs line-height-xs">Main</div>
        <i class="icon-menu" title="Main"></i>
      </li>
      <li :class="get_nav_top_class(item)" v-for="item in nav_links">
        <template v-if="item.sub">
          <a href="#" :class="get_nav_sub_clas(item)">
            <i :class="item.icon"></i>
            <span>{{item.title}}</span>
          </a>
        </template>
        <template v-else>
          <router-link :to="item.link" :class="get_nav_sub_clas(item)">
            <i v-if="item.icon" :class="item.icon"></i>
            <span>{{item.title}}</span>
            <template v-if="item.comming_soon">
              <span class="badge bg-transparent align-self-center ml-auto">Coming soon</span>
            </template>
            <template v-if="item.badge">
              <span :class="item.badge.class" v-html="item.badge.content"></span>
            </template>
          </router-link>
        </template>

        <template v-if="item.sub">
          <ul class="nav nav-group-sub" data-submenu-title="Logs">
            <li class="nav-item" v-for="sub in item.sub">
              <router-link :to="sub.link" :class="get_nav_sub_clas(sub)">
                <i v-if="sub.icon" :class="sub.icon"></i>
                <span>{{sub.title}}</span>
                <template v-if="sub.comming_soon">
                  <span class="badge bg-transparent align-self-center ml-auto">Coming soon</span>
                </template>
                <template v-if="sub.badge">
                  <span :class="sub.badge.class" v-html="sub.badge.content"></span>
                </template>
              </router-link>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
// https://www.jiuxingrenge.com/icons_icomoon.html
export default {
  name: 'LeftNav',
  methods: {
    get_nav_sub_clas: el => {
      if (el.comming_soon !== undefined) {
        return 'nav-link disabled';
      }
      return 'nav-link';
    },
    get_nav_top_class: function (el) {
      if (el.sub && el.sub.length !== 0) {
        return 'nav-item nav-item-submenu';
      }
      return 'nav-item';
    }
  },
  data () {
    return {
      nav_links: [
        {
          title: 'Начало',
          icon: 'icon-home4',
          link: { name: 'HelloWorld' }
        },
        {
          title: 'Продукти',
          icon: 'icon-stack3',
          link: { name: 'ProductsList' }
        },
        {
          title: 'Страници',
          icon: 'icon-stack-text',
          link: { name: 'PagesList' }
        },
        {
          title: 'Модули',
          icon: 'icon-cog4',
          link: { name: 'ModuleList' }
        },
        {
          title: 'SEO',
          icon: ' icon-google',
          link: { name: 'SEOList' }
        },
        {
          title: 'FAQ',
          icon: 'icon-question7',
          link: { name: 'FAQList' }
        },
        {
          title: 'Категории',
          icon: 'icon-tree7',
          link: { name: 'CategoriesList' }
        },
        {
          title: 'Цени',
          icon: ' icon-price-tag',
          link: { name: 'MassPriceUpdate' }
        },
        {
          title: 'Контакти',
          icon: 'icon-bubbles2',
          link: { name: 'ContactUsList' }
        },
        {
          title: 'Потребители',
          icon: 'icon-users',
          link: { name: 'UsersList' }
        },
        {
          title: 'Файлов мениджър',
          icon: 'icon-download',
          link: { name: 'FilemanagerImagesList' }
        }
      ]
    }
  }
}
</script>
