<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Управление на модули</h5>
          <div>

          </div>
        </div>
        <div class="card-body" id="_block_me">
          <div class="table-responsive">
            <table class="own_table table text-nowrap">
              <thead>
                <tr>
                  <th class="first_td">Име</th>
                  <th>Стойност</th>
                  <th class="text-right" style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in modules">
                  <td class="first_td">{{item.title}}</td>
                  <td>{{item.content}}</td>
                  <td>
                    <router-link :to="{name:'ModuleEdit', params: {'id':item.id} }">
                      <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt, faPlus)
import _ from 'lodash';

export default {
  name: 'List',
  methods: {
    get_data: function () {
      ui.block($('#_block_me'))
      api.get('modules/list').then(
        function (res) {

          var final = []
          _.each(res.data.modules, function (v) {
            v['content'] = _.truncate(v['content'], { length: 50 })
            final.push(v)
          })
          this.modules = final

          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Модули',
          link: { name: 'ModuleList' }
        }
      ],
      modules: []
    }
  }
}
</script>
