// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tox-statusbar__branding {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MassPriceUpdate/Edit.vue","webpack://./Edit.vue"],"names":[],"mappings":"AACA;EACE,wBAAA;ACAF","sourcesContent":["\n.tox-statusbar__branding {\n  display: none !important;\n}\n",".tox-statusbar__branding {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
