<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Качване на снимки към продукт</h5>
        </div>
        <div class="card-body" id="_block_me">
          <div class="upload">
            <ul>
              <li v-for="(file, index) in files" :key="file.id">
                <span>{{file.name}}</span> -
                <span>{{file.size}} bytes</span> -
                <span v-if="file.error">ГРЕШКА {{file.error}}</span>
                <span v-else-if="file.success">Качено!</span>
                <span v-else-if="file.active">active</span>
                <span v-else-if="file.active">active</span>
                <span v-else></span>
              </li>
            </ul>
            <div class="example-btn">
              <file-upload
                class="btn btn-primary"
                :post-action="upload_url"
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png, image/gif, image/jpeg, image/webp"
                :multiple="true"
                :size="1024 * 1024 * 10"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload">
                <i class="fa fa-plus"></i>
                Избор на файлове
              </file-upload>
              <span style="margin-left: 10px;">
                <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                  Качи
                </button>
                <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
                  <i class="fa fa-stop" aria-hidden="true"></i>
                  Спри качването
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Storage from '@/storage';

import PageHeader from '@/components/template/PageHeader';
const VueUploadComponent = require('vue-upload-component')

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    inputFile: function (newFile, oldFile) {},
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = '';
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
    submit: function () {},
    get_data: function () {
      if (this.id === undefined) {
        this.$router.push('/products/lists')
        return false
      }
    }
  },
  props: ['id'],
  components: {
    'file-upload': VueUploadComponent,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      upload_url:
        '/backend/api/images/upload/' + this.id + '?auth_token=' + Storage.get('token'),
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Продукти',
          link: { name: 'ProductsList' }
        }
      ],
      files: []
    }
  }
}
</script>
