<template>
    <div>
        <div class="navbar navbar-expand-md navbar-dark bg-indigo navbar-static">
            <div class="navbar-brand">
                <router-link to="/" class="d-inline-block">
                    <img src="@/assets/images/logo_light.png">
                </router-link>
            </div>
            <ul class="navbar-nav ml-md-auto">
                <li class="nav-item"> </li>
            </ul>
        </div>
        <div class="page-content">
            <div class="content-wrapper">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .card-body {
        width:350px;
    }
</style>
