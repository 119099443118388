import block from 'blockui-npm'

var ui = {
  block: function (el, message) {
    if (message === undefined) {
      message =
        '<span class="font-weight-semibold"><i class="icon-spinner4 spinner mr-2"></i>&nbsp; Обновяване на данните</span>'
    }
    el.block({
      // message: '<i class="icon-spinner4 spinner"></i>',
      message: message,
      overlayCSS: {
        backgroundColor: '#fff',
        opacity: 0.8,
        cursor: 'wait'
      },
      css: {
        border: 0,
        padding: 0,
        backgroundColor: 'transparent'
      }
    })
  },
  unblock: function (el) {
    el.unblock()
  }
}

export default ui
