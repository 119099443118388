<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Списък с продукти</h5>
          <router-link :to="{name:'ProductAdd'}">Добави</router-link>
        </div>
        <div class="card-body" id="_block_me">
          <div class="table-responsive">
            <table class="own_table table text-nowrap">
              <thead>
                <tr>
                  <th class="first_td">Име на продукт</th>
                  <th>Цена купува</th>
                  <th>Цена продава</th>
                  <th>Статус</th>
                  <th class="text-right" style="width: 20px;"></th>
                </tr>
              </thead>
              <draggable item-key="id" v-model="products" @change="reorder" tag="tbody" handle=".handle">
                <template #item="{ element }">
                  <tr>
                    <td class="first_td">{{element.name}}</td>
                    <td>{{element.buy_price}} лв.</td>
                    <td>{{element.sell_price}} лв.</td>
                    <td>
                      <template v-if="element.status === 'Public'">Публукувано</template>
                      <template v-else>Скрито</template>
                    </td>
                    <td>
                      <router-link :to="{name:'ProductImagesView', params: {'id':element.id} }">
                        <font-awesome-icon icon="images"></font-awesome-icon>
                      </router-link>&nbsp;
                      <router-link :to="{name:'ProductEdit', params: {'id':element.id} }">
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                      </router-link>&nbsp;
                      <a href="#" v-on:click.prevent="deleteEl(element)">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                      </a>
                      &nbsp;
                      <a href="#" class="handle">
                        <font-awesome-icon icon="align-justify"></font-awesome-icon>
                      </a>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt,
  faImages,
  faTrashAlt,
  faAlignJustify
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

import draggable from 'vuedraggable';

library.add(faPencilAlt, faImages, faTrashAlt, faAlignJustify)
import _ from 'lodash';

export default {
  name: 'List',
  methods: {
    reorder: function () {
      var f = []
      _.each(this.products, function (el, k) {
        f.push({
          id: el.id,
          order: k
        })
      })
      api.post('product/saveOrder', { order: JSON.stringify(f) })
    },
    deleteEl: function (el) {
      if (confirm('Сигурни ли сте ?')) {
        api.post('product/delete', { id: el.id }).then(
          function () {
            this.get_data()
          }.bind(this)
        )
      }
    },
    get_data: function () {
      ui.block($('#_block_me'))
      api.get('products/list').then(
        function (res) {
          this.products = res.data.products
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    draggable: draggable,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      list: [],
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Продукти',
          link: { name: 'ProductsList' }
        }
      ],
      products: []
    }
  }
}
</script>

<style>
.sortable-ghost {
  padding: 15px 10px;
  background-color: whitesmoke;
  min-width: 30vw;
  max-width: 90vw;
  margin: 0 auto 10px;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
  transition: transform 0.25s cubic-bezier(0.02, 1.01, 0.94, 1.01);
  will-change: transform;
}
</style>
