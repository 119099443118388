// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first_td[data-v-47862914] {
  padding-left: 0px;
}
.first_td .media-title[data-v-47862914] {
  color: #333 !important;
}
.first_td .media-body[data-v-47862914] {
  margin-top: -6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modules/List.vue","webpack://./List.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;ACAF;ADDA;EAGI,sBAAA;ACCJ;ADJA;EAMI,gBAAA;ACCJ","sourcesContent":["\n.first_td {\n  padding-left: 0px;\n  .media-title {\n    color: #333 !important;\n  }\n  .media-body {\n    margin-top: -6px;\n  }\n}\n",".first_td {\n  padding-left: 0px;\n}\n.first_td .media-title {\n  color: #333 !important;\n}\n.first_td .media-body {\n  margin-top: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
