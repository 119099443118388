<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">{{title}}</h5>
        </div>

        <div class="card-body" id="_block_me">
          <form action="" v-on:submit.prevent="submit">

            <div class="form-group">
              <label>Злато bid: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.AU_bid" placeholder="1020.41">
            </div>

            <div class="form-group">
              <label>Злато ask: (в Евро) - ако не се подаде ще ползва стойността от bid</label>
              <input type="text" class="form-control" v-model="html_module.AU_ask" placeholder="1020.41">
            </div>

            <br>
            <div class="form-group">
              <label>Сребро bid: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.AG_bid" placeholder="1020.41">
            </div>

            <div class="form-group">
              <label>Сребро ask: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.AG_ask" placeholder="1020.41">
            </div>
            <br>

            <div class="form-group">
              <label>Платина bid: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.PT_bid" placeholder="1020.41">
            </div>

            <div class="form-group">
              <label>Платина ask: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.PT_ask" placeholder="1020.41">
            </div>
            <br>

            <div class="form-group">
              <label>Паладий bid: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.PD_bid" placeholder="1020.41">
            </div>

            <div class="form-group">
              <label>Паладий ask: (в Евро)</label>
              <input type="text" class="form-control" v-model="html_module.PD_ask" placeholder="1020.41">
            </div>

            <div>
              <div class="float-left">
                  <button type="submit" class="btn bg-teal-400 legitRipple">Запази <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.tox-statusbar__branding {
  display: none !important;
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import api from '@/api_client'
import ui from '@/helpers/ui'

import PageHeader from '@/components/template/PageHeader'

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    submit: function () {
      ui.block(
        $('#_block_me'),
        '<i class="icon-spinner4 spinner mr-2"></i>&nbsp; Запазване на промените'
      )
      let data = JSON.parse(JSON.stringify(this.html_module))

      if (this.id !== undefined) {
        data['id'] = this.id
      }

      api.post('update/mass-price-update', data).then(
        function (res) {
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  props: ['id'],
  mounted: function () {

  },
  data () {
    return {
      title: 'Редактиране',
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Страници',
          link: { name: 'ModuleList' }
        }
      ],
      html_module: {
        AU_bid: '',
        AU_ask: '',
        AG_bid: '',
        AG_ask: '',
        PT_bid: '',
        PT_ask: '',
        PD_bid: '',
        PD_ask: ''
      }
    }
  }
}
</script>
