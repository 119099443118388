import {createRouter, createWebHistory } from 'vue-router';

import HelloWorld from '@/components/HelloWorld'
import Login from '@/components/Login/Login'

import ProductList from '@/components/Products/List'
import ProductEdit from '@/components/Products/Edit'
import ProductImageEdit from '@/components/Products/ImageEdit'

import ProductImagesList from '@/components/Products/Images'
import ProductImagesUpload from '@/components/Products/ImageUpload'

import FilemanagerImagesList from '@/components/Filemanager/List'
import FilemanagerImagesUpload from '@/components/Filemanager/ImageUpload'

import PagesList from '@/components/Pages/List'
import PagesEdit from '@/components/Pages/Edit'

import FAQList from '@/components/FAQ/List'
import FAQEdit from '@/components/FAQ/Edit'

import CategoriesList from '@/components/Categories/List'
import CategoriesEdit from '@/components/Categories/Edit'

import SEOList from '@/components/Seo/List'
import SEOEdit from '@/components/Seo/Edit'

import ModuleList from '@/components/Modules/List'
import ModuleEdit from '@/components/Modules/Edit'

import ContactUsList from '@/components/ContactUs/List'

import MassPriceUpdate from '@/components/MassPriceUpdate/Edit'

import UsersList from '@/components/Admin/List'
import UsersEdit from '@/components/Admin/Edit'

import Store from '@/storage'

const routes = 
[
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/seo/lists',
      name: 'SEOList',
      component: SEOList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/seo/edit/:id',
      name: 'SEOEdit',
      props: true,
      component: SEOEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/seo/new',
      name: 'SEONew',
      // props: true,
      component: SEOEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users/list',
      name: 'UsersList',
      component: UsersList,
      meta: {
        requiresAuth: true
      }
    },
    {
      name: 'UsersEdit',
      path: '/users/edit/:id',
      props: true,
      component: UsersEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/lists',
      name: 'ProductsList',
      component: ProductList,
      meta: {
        requiresAuth: true
      }
    },
    {
      component: FAQList,
      path: '/faq/list',
      name: 'FAQList',
      meta: {
        requiresAuth: true
      }
    },
    {
      component: FAQEdit,
      path: '/faq/edit/:id',
      name: 'FAQEdit',
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      component: FAQEdit,
      path: '/faq/add',
      name: 'FAQAdd',
      meta: {
        requiresAuth: true
      }
    },
    {
      component: CategoriesList,
      path: '/categories/list',
      name: 'CategoriesList',
      meta: {
        requiresAuth: true
      }
    },
    {
      component: CategoriesEdit,
      path: '/categories/edit/:id',
      name: 'CategoriesEdit',
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      component: CategoriesEdit,
      path: '/categories/add',
      name: 'CategoriesAdd',
      meta: {
        requiresAuth: true
      }
    },
    {
      component: PagesList,
      path: '/pages/list',
      name: 'PagesList',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pages/new',
      name: 'PagesNew',
      component: PagesEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pages/edit/:id',
      name: 'PagesEdit',
      props: true,
      component: PagesEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/edit/:id',
      name: 'ProductEdit',
      props: true,
      component: ProductEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/image-edit/:id',
      name: 'ProductImageEdit',
      props: true,
      component: ProductImageEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/modules/list',
      name: 'ModuleList',
      component: ModuleList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/modules/edit/:id',
      name: 'ModuleEdit',
      props: true,
      component: ModuleEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/add',
      name: 'ProductAdd',
      props: true,
      component: ProductEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/upload_image/:id',
      props: true,
      name: 'ProductImagesUpload',
      component: ProductImagesUpload,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/filemanager/list',
      props: true,
      name: 'FilemanagerImagesList',
      component: FilemanagerImagesList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/filemanager/upload',
      props: true,
      name: 'FilemanagerImagesUpload',
      component: FilemanagerImagesUpload,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products/images/:id',
      name: 'ProductImagesView',
      props: true,
      component: ProductImagesList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/contacts/list',
      name: 'ContactUsList',
      props: true,
      component: ContactUsList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/mass-price-update',
      name: 'MassPriceUpdate',
      props: true,
      component: MassPriceUpdate,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
];

const rotuer = createRouter({
    history: createWebHistory(),
    routes
});

rotuer.beforeEach((to, from, next) => 
{
  let token = Store.get('token')

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (token !== undefined || token != null) {
      next()
    } else {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  } else {
    next()
  }
});

export default rotuer