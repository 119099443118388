var CryptoJS = require("crypto-js");

let Storage = {
    config: {
        enc_key: "&Myfaslkj&14^&*14"
    },
    del: function(item){
        localStorage.removeItem(item);
    },
    clear: function(){
        localStorage.clear()
    },
    set: function(key,value) {
        if(typeof value === 'object') {
            value = JSON.stringify(value);   
        }
        var cryptobject = CryptoJS.AES.encrypt(value, this.config.enc_key);
        localStorage.setItem(key,  cryptobject + '');
    },
    get: function (key) {
        let original = localStorage.getItem(key);
        if (original == undefined || original == "" || original == null) {
            return original;
        } else {
            let decrypted = CryptoJS.AES.decrypt(original, this.config.enc_key).toString(CryptoJS.enc.Latin1) 
            
            try {
                var json = JSON.parse(decrypted);
                return json
            } catch(e) { 
                return decrypted
            }

        }
    }
}

export default Storage