<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">{{title}}</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
              <div class="form-group">
                <label>Име:</label>
                <input type="text" class="form-control" v-model="model.name" required>
              </div>
              <div class="form-group">
                <label>URL:</label>
                <input type="text" class="form-control" v-model="model.url">
              </div>
              <div class="form-group">
                <label>Име на категория в хамбургер менюто на мобилната версия (използва се само за категории от първо ниво):</label>
                <input type="text" class="form-control" v-model="model.mobileLableAllProducts">
              </div>
              <div class="form-group">
                <label>Заглавие на първата лента за разделение в тази категория:</label>
                <input type="text" class="form-control" v-model="model.mainSeparatorLineTitle">
              </div>
              <div class="form-group">
                <label>Заглавие (H1):</label>
                <input type="text" class="form-control" v-model="model.title" required>
              </div>
              <div class="form-group">
                <label>Номер за подредба (най-малкото число е най-високата позиция):</label>
                <input type="number" class="form-control" v-model="model.orderBy" required>
              </div>
              <label for="-"><span>Описание</span></label>
              <editor
                v-model="model.description"
                :init="editor_init"  
                licenseKey="gpl"
              ></editor>
              <br>
            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
              <div class="float-right">
                <router-link :to="{name:'CategoriesList'}">Отказ</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.tox-statusbar__branding {
  display: none !important;
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Editor from '@tinymce/tinymce-vue';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt)
import _ from 'lodash';

export default {
  name: 'List',
  methods: {
    submit: function () {
      let data = _.clone(this.model)
      ui.block(
        $('#_block_me'),
        '<i class="icon-spinner4 spinner mr-2"></i>&nbsp; Запазване на промените'
      )

      api.post('categories/save/' + this.id, data).then(
        function () {
          ui.unblock($('#_block_me'))
          this.$router.push('/categories/list')
        }.bind(this)
      )
    },
    get_data: function () 
    {
      if (this.id !== undefined) 
      {
        api.get('/categories/get/' + this.id).then(
          function (res) {
            this.model = res.data.data;

            // load the parent category only if you edit a level 2
            if (this.model.level == 2)
            {
              this.getListParentCategorySelect();
            }
          }.bind(this)
        )
      }
      else 
      {
        this.title = 'Добавяне';
        this.getListParentCategorySelect();
      }
    },
    getListParentCategorySelect: function()
    {
      api.get('categories/list').then(function (res) 
      {
            var listOfOptions = [];

            for (const [key, value] of Object.entries(res.data.payload)) 
            {
              if (value.level == 1) 
              {
                listOfOptions.push({ id: value.id, name: value.name });
              }
            }

            this.schema.fields.push({
              type: 'select',
              inputType: 'text',
              label: 'Основна категория',
              model: 'parentCategoryId',
              values: listOfOptions,
              required: true
            });

          }.bind(this)
      );
    }
  },
  components: {
    editor: Editor,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  props: ['id'],
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      title: 'Редактиране',
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Категории',
          link: { name: 'CategoriesList' }
        }
      ],
      editor_init: {
        license_key: 'gpl',
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'link',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
          'image'
        ],
        toolbar:
          'formatselect | bold italic | alignleft aligncenter alignright alignjustify | removeformat | link |  numlist bullist outdent indent | image'
      },
      page: {
        title: '',
        content: ''
      },
      model: { id: 0, name: '', url: '', description: '' , mobileLableAllProducts: '' },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>
