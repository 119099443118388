<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">{{title}}</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
            <div class="form-group">
              <label>URL:</label>
              <input type="text" class="form-control" name="url" v-model="model.url" required>
            </div>
            <div class="form-group">
              <label>Meta:</label>
              <textarea rows="30" v-model="model.content" class="form-control"></textarea>
            </div>
            <div class="form-group">
              <label>Body:</label>
              <textarea rows="30" v-model="model.body_content" class="form-control"></textarea>
            </div>
            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
              <div class="float-right">
                <router-link :to="{name:'SEOList'}">Отказ</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.tox-statusbar__branding {
  display: none !important;
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    submit: function () {
      ui.block($('#_block_me'))
      api.post('/seo/save', this.model).then(
        function (res) {
          this.$router.push('/seo/lists')
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    },
    get_data: function () {
      if (this.id) {
        api.get('/seo/get/' + this.id).then(
          function (res) {
            this.model = res.data.product
          }.bind(this)
        )
      }
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  props: ['id'],
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      title: 'Редактиране',
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'SEO',
          link: { name: 'SEOList' }
        }
      ],
      page: {
        title: '',
        content: ''
      },
      model: {
        url: '',
        content: ''
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>
