<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Управление на снимките към продукт</h5>
          <router-link :to="{name:'ProductImagesUpload', params: {id:id} }">Качване</router-link>
        </div>
        <div class="card-body" id="_block_me">
          <template v-if="loaded">
            <template v-if="images.length !== 0">
              <div class="row">
                <div v-for="image in images" class="col-sm-6 col-lg-3">
                  <div class="card">
                    <div class="card-img-actions m-1">
                      <img class="card-img img-fluid" :src="image.path" alt>
                      <div class="card-img-actions-overlay card-img">
                        <a
                          href="#"
                          v-on:click.prevent="deleteImage(image.id)"
                          class="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round legitRipple"
                          data-popup="lightbox"
                          rel="group"
                        >
                          <i class="icon-trash"></i>
                          
                        </a>

                        <a
                          href="#"
                          v-on:click.prevent="editImage(image.id)"
                          class="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round legitRipple"
                          data-popup="lightbox"
                          rel="group"
                        >
                          <i class="icon-pencil"></i>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center">Няма качени снимки все още!</div>
            </template>
          </template>
          <template v-else>
            <div style="min-height:200px;"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';
import Editor from '@tinymce/tinymce-vue';

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    deleteImage: function (id) {
      if (confirm('Сигурни ли сте?')) {
        api.post('/images/delete/' + id).then(
          function () {
            this.get_data()
          }.bind(this)
        )
      }
    },
    editImage: function (id) {
      this.$router.push('/products/image-edit/' + id);
    },
    submit: function () {},
    get_data: function () {
      if (this.id === undefined) {
        this.$router.push('/products/lists')
        return false
      }
      ui.block($('#_block_me'))
      api.get('images/product/' + this.id).then(
        function (res) {
          this.images = res.data.images
          this.loaded = true
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  props: ['id'],
  components: {
    editor: Editor,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Продукти',
          link: { name: 'ProductsList' }
        }
      ],
      loaded: false,
      images: []
    }
  }
}
</script>
