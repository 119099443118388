<template>
    <div class="mb-3 ">
        <div class="page-header page-header-light">
            <div class="page-header-content">
                <template v-if="title">
                    <div class="page-title">
                        <h6> <span class="font-weight-semibold">{{title}}</span> </h6>
                    </div>
                </template>
                <template v-if="title_data.length != 0">
                  
                  <template v-if="title_data.type == 'view_device'">
                        <div class="page-title d-flex">
                            <h6>
                                <i class="text-green-800 icon-android mr-2"></i> 
                                <span class="font-weight-semibold">Device info</span>
                                <small class="d-block text-muted">Last used time:</small>
                            </h6>
                        </div>
                  </template>    

                </template>
            </div>
            <div v-if="breadbrumbs.length != 0" class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                <div class="d-flex">
                    <div class="breadcrumb">
                        <template v-for="(item, index) in breadbrumbs">
                            <template v-if="index == breadbrumbs.length - 1">
                                <span class="breadcrumb-item active">{{item.name}}</span>
                            </template>
                            <template v-else>
                                <router-link  class="breadcrumb-item" :to="item.link">
                                    <i v-if="item.icon" :class="item.icon"></i> {{item.name}}
                                </router-link>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: ["title", "breadbrumbs","page_title", "title_data"],
  data() {
    return {};
  }
};
</script>