<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>

    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">{{title}}</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
            <div class="form-group">
              <label>Заглавие:</label>
              <input type="text" class="form-control" v-model="page.title" placeholder="пр. За нас">
            </div>
            <div class="form-group">
              <label>Съдържание:</label>
              <editor
                v-model="page.content"
                placeholder="da"
                :init="editor_init" 
                licenseKey="gpl"
              ></editor>
            </div>
            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
              <div class="float-right">
                <router-link :to="{name:'PagesList'}">Отказ</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.tox-statusbar__branding {
  display: none !important;
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Editor from '@tinymce/tinymce-vue';

import api from '@/api_client';
import ui from '@/helpers/ui';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    submit: function () {
      ui.block(
        $('#_block_me'),
        '<i class="icon-spinner4 spinner mr-2"></i>&nbsp; Запазване на промените'
      )
      let data = JSON.parse(JSON.stringify(this.page))

      if (this.id !== undefined) {
        data['id'] = this.id
      }

      api.post('pages/save', data).then(
        function (res) {
          ui.unblock($('#_block_me'))
          this.$router.push('/pages/list')
        }.bind(this)
      )
    },
    get_data: function () {
      if (this.id !== undefined) {
        ui.block($('#_block_me'))
        api.get('/pages/get/' + this.id).then(
          function (res) {
            this.page = res.data.page

            this.breadbrumbs.push({
              name: 'Редактиране на ' + res.data.page.title,
              link: ''
            })
            ui.unblock($('#_block_me'))
          }.bind(this)
        )
      } else {
        this.title = 'Добавяне на нова страница';
        this.breadbrumbs.push({
          name: 'Нова страница',
          link: ''
        })
      }
    }
  },
  components: {
    editor: Editor,
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  props: ['id'],
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      title: 'Редактиране',
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Страници',
          link: { name: 'PagesList' }
        }
      ],
      page: {
        title: '',
        content: ''
      },
      editor_init: {
        license_key: 'gpl',
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'link',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
          'image'
        ],
        toolbar:
          'filemanager | formatselect | bold italic | alignleft aligncenter alignright alignjustify | removeformat | table link |  numlist bullist outdent indent | image',      
      }
    }
  }
}
</script>
