<template>
  <div>
    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Управление на снимка</h5>
        </div>
        <div class="card-body" id="_block_me">
          <form action v-on:submit.prevent="submit">
            <div class="form-group">
              <label>Alt:</label>
              <input
                type="text"
                class="form-control"
                v-model="image.alt"
                placeholder="пр. За нас"
              >
            </div>
            <div>
              <div class="float-left">
                <button type="submit" class="btn bg-teal-400 legitRipple">
                  Запази
                  <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import api from '@/api_client';
import ui from '@/helpers/ui';

library.add(faPencilAlt)

export default {
  name: 'List',
  methods: {
    submit: function () {
      let data = JSON.parse(JSON.stringify(this.image))
      if (this.id !== undefined) {
        data['id'] = this.id
      }
      ui.block($('#_block_me'))
      api.post('image/save', data).then(
        function (res) {
          this.$router.push('/products/lists')
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    },
    get_data: function () {
      if (this.id !== undefined) {
        ui.block($('#_block_me'))
        api.get('image/details/' + this.id).then(
          function (res) {
            this.image = res.data[0]
            ui.unblock($('#_block_me'))
          }.bind(this)
        )
      }
    }
  },
  props: ['id'],
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        }
      ],
      image: {
        alt: '',
        id: ''
      }
    }
  }
}
</script>
