<template>
  <div>
    <PageHeader title :breadbrumbs="breadbrumbs" title_data/>
    <div class="content">
      <div class="card">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">За контакти</h5>
          <div>
            <a :href="csv_link" target="_blank">Свали CSV</a>
          </div>
        </div>
        <div class="card-body" id="_block_me">
          <div class="table-responsive">
            <table class="own_table table text-nowrap">
              <thead>
                <tr>
                  <th class="first_td">От дата</th>
                  <th class>Име</th>
                  <th>Е-поща</th>
                  <th class="text-right" style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in contacts">
                  <td class="first_td">{{item.time}}</td>
                  <td class>{{item.names}}</td>
                  <td>{{item.email}}</td>
                  <td>
                    <a href="#" v-on:click.prevent="delMe(item)">
                      <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.first_td {
  padding-left: 0px;
  .media-title {
    color: #333 !important;
  }
  .media-body {
    margin-top: -6px;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import api from '@/api_client';
import ui from '@/helpers/ui';
import Store from '@/storage';

import PageHeader from '@/components/template/PageHeader';

library.add(faPencilAlt, faPlus, faTrashAlt)

export default {
  name: 'List',
  methods: {
    delMe: function (el) {
      if (confirm('Сигурни ли сте ?')) {
        api.post('contacts/delete', { id: el.id }).then(
          function () {
            this.get_data()
          }.bind(this)
        )
      }
    },
    get_data: function () {
      ui.block($('#_block_me'))
      api.get('contacts/get').then(
        function (res) {
          this.contacts = res.data.contact_us
          ui.unblock($('#_block_me'))
        }.bind(this)
      )
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    PageHeader: PageHeader
  },
  mounted: function () {
    this.get_data()
  },
  data () {
    return {
      csv_link: '/api/contacts/csv?auth_token=' + Store.get('token'),
      breadbrumbs: [
        {
          name: 'Начало',
          icon: 'icon-home2 mr-2',
          link: { name: 'HelloWorld' }
        },
        {
          name: 'Контакти',
          link: { name: 'ContactUsList' }
        }
      ],
      contacts: []
    }
  }
}
</script>
